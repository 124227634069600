const MESSAGE_STRINGS = {
	// SMFToast Component
	"SMFToast.message.SUCCESS": "Your changes were saved Successfully!",
	"SMFToast.message.ERROR":
		"Oops, something went wrong. Please try again later!",
	"SMFToast.message.WARNING": "Oops, something went wrong!",
	"SMFToast.message.INFO": "Please wait",

	// Common Validations
	"Validation.requiredField": "Required field",
	"Validation.invalidTime": "Invalid Time",
	"Validation.invalidDate": "Invalid Date",

	// Time Picker
	"TimePicker.select.time": "Select Time",

	// Data Reports
	"DataReports.main.header": "Data Reports",
	"DataReports.main.caption":
		"Use this tool to export an XLSX timeseries data file containing both processed and raw data values for a given Functional Area within the system for a specified time range.",
	"DataReports.TimeRange.header": "Time Range",
	"DataReports.TimeRange.tooltip":
		"The maximum allowable duration for the specified time range is 24 hours",
	"DataReports.TimeRange.startDate": "Production Day Start Date",
	"DataReports.TimeRange.startTime": "Start Time",
	"DataReports.TimeRange.EndTime": "End Time",
	"DataReports.FunctionalArea.header": "Functional Area",
	"DataReports.FunctionalArea.error":
		"You must select a Functional Area to download a report",
	"DataReports.FunctionalArea.FH": "Factory Health",
	"DataReports.FunctionalArea.SOC": "Standard Operating Conditions",
	"DataReports.FunctionalArea.OEE": "Overall Equipment Effectiveness",
	"DataReports.FunctionalArea.EC": "Equipment Constraints",
	"DataReports.button.download": "Generate Report",
	"DateReport.validation.dateField.invalidDate":
		"Value must be a valid date format.",
	"DateReport.validation.dateField.pastDate":
		"Value must be within 365 days of the current date.",
	"DateReport.validation.dateField.futureDate":
		"Value must be a past date or the current date.",
	"DateReport.validation.timeField.invalidTime":
		"Value must be a valid time format (e.g.,19:00).",
	"DateReport.validation.startTime.afterTime":
		"The Start Time must be at least 30 minutes earlier than the current time.",
	"DateReport.validation.endTime.afterTime":
		"The End Time must be at least 30 minutes earlier than the current time.",
	"DataReports.HistoryTable.tooltip":
		"The Reports section provides a list of report generation requests sorted by submission date. Use the table to download and track the status of individual reports.",
	"DataReports.HistoryTable.title": "Reports",
	"DataReports.HistoryTable.Column.CreatedOn": "Created on",
	"DataReports.HistoryTable.Column.TimeRange": "Time Range",
	"DataReports.HistoryTable.Column.Requester": "Requester",
	"DataReports.HistoryTable.Column.FunctionalArea": "Functional Area",
	"DataReports.HistoryTable.Column.Status": "Status",
	"DataReports.HistoryTable.Success.Toast":
		"Your request to generate a data report was successfully submitted!",
	"DataReports.Status.Failed": "Error generating report. Please try again.",
	"DataReports.Status.Succeeded": "Download",
	"DataReports.Status.InsufficientData":
		"Report not generated: insufficient data",
	"DataReports.HistoryTable.IdenticalInProgress.Toast":
		"An identical data report request has been submitted. You can review this file after it has been successfully generated.",
	"DataReports.HistoryTable.Identical.Toast":
		"An identical data report is currently available for download in the Reports table.",
	"DataReports.HistoryTable.Insufficient.Toast":
		"An identical data report has been submitted. Currently, there is insufficient data during this time range to generate this report.",
	"DataReports.HistoryTable.Download.FH":
		"The Factory Health Data Report file has been successfully downloaded!",
	"DataReports.HistoryTable.Download.EC":
		"The Equipment Constraints Data Report file has been successfully downloaded!",
	"DataReports.HistoryTable.Download.OEE":
		"The OEE Data Report file has been successfully downloaded!",
	"DataReports.HistoryTable.Download.SOC":
		"The Standard Operating Conditions Data Report file has been successfully downloaded!",
	"DataReports.HistoryTable.Download.DEFAULT":
		"The Data Report file has been successfully downloaded!",

	// Pagination Footer
	"GeneralTable.Pagination.caption.label1": `Displaying`,
	"GeneralTable.Pagination.caption.label2": "of",
	"GeneralTable.Pagination.caption.label3": "results",
	"GeneralTable.Pagination.caption.rowsLabel": "Rows per page",
	"GeneralTable.Pagination.caption.noData": "No data found",

	// Dropdown
	"GeneralDropdown.default.placeholder": "Select",
};

export default MESSAGE_STRINGS;
