import {
	Box,
	FormControl,
	IconButton,
	MenuItem,
	Paper,
	Typography,
	styled,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import MESSAGE_STRINGS from "../../../constants/en-us";
import GeneralDropdown from "../../GeneralDropdown";
import LeftArrow from "../../../assets/img/leftArrow.svg";
import RightArrow from "../../../assets/img/rightArrow.svg";

const PaginationFooterContainer = styled(Paper)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-end",
	alignItems: "center",
	background: theme.palette.background.darkishBlackBlue,
	borderRadius: 0,
	padding: "0.25rem",
	fontSize: "0.875rem",
	fontWeight: 400,
	fontFamily: "Open Sans",
	lineHeight: "1.43",
	WebkitFlexShrink: "0",
	MsFlexNegative: "0",
	flexShrink: "0",
	[theme.breakpoints.down("xs")]: {
		flexDirection: "column-reverse",
	},
	"& .tablePaginationContainer-displayingMessage": {
		marginRight: "2.625rem",
		[theme.breakpoints.down("xs")]: {
			paddingTop: "1rem",
			paddingBottom: "1rem",
		},
	},
	"& .tablePaginationContainer-rowsPerPage": {
		marginRight: "1rem",
		display: "flex",
		alignItems: "center",
		"& .tablePaginationContainer-rowLabelTitle": {
			marginRight: "0.625rem",
		},
	},
	"& .tablePaginationContainer-pageNav": {
		display: "flex",
		alignItems: "center",
	},
}));

const FooterIconButton = styled(IconButton)(({ theme }) => ({
	padding: "0.75rem 0.25rem",
	"&:disabled": {
		stroke: theme.palette.background.shuttleGray,
		filter:
			"invert(39%) sepia(5%) saturate(349%) hue-rotate(175deg) brightness(97%) contrast(90%)",
	},
}));
function getLabelDisplayedRows({ from, to, count }) {
	return `${
		MESSAGE_STRINGS["GeneralTable.Pagination.caption.label1"]
	} ${from}–${to} ${
		MESSAGE_STRINGS["GeneralTable.Pagination.caption.label2"]
	} ${count !== -1 ? count : `more than ${to}`} ${
		MESSAGE_STRINGS["GeneralTable.Pagination.caption.label3"]
	}`;
}

function TablePaginationFooter({
	pageSizeList = [5, 10, 20, 30, 40, 50],
	onNextPageClicked,
	setPageSize,
	onPreviousPageClicked,
	canPreviousPage,
	canNextPage,
	leftHandMessage = null,
	count,
	rowsPerPage,
	page,
	tableName = "smf-table",
}) {
	function onPageChange(e) {
		setPageSize(+e.target.value);
	}
	const totalPages = Math.ceil(count / rowsPerPage);
	const getLabelDisplayedRowsTo = () => {
		if (count === -1) {
			return (page + 1) * rowsPerPage;
		}

		return Math.min(count, (page + 1) * rowsPerPage);
	};
	return (
		<PaginationFooterContainer
			className="tablePaginationContainer"
			data-testid={`${tableName}-pagination-container`}
		>
			<Box>{leftHandMessage}</Box>
			<Box
				className="tablePaginationContainer-displayingMessage"
				data-testid={`${tableName}-pagination-label`}
			>
				{getLabelDisplayedRows({
					from: count === 0 ? 0 : page * rowsPerPage + 1,
					to: getLabelDisplayedRowsTo(),
					count: count === -1 ? -1 : count,
					page,
				})}
			</Box>
			<div className="tablePaginationContainer-rowsPerPage">
				<Typography
					variant="subtitle"
					className="tablePaginationContainer-rowLabelTitle"
					data-testid={`${tableName}-pagination-dropdown-label`}
				>
					{MESSAGE_STRINGS["GeneralTable.Pagination.caption.rowsLabel"]}
				</Typography>
				<FormControl sx={{ width: "70px" }}>
					<GeneralDropdown
						value={rowsPerPage}
						onChange={onPageChange}
						borderRadius={4}
						data-testid={`${tableName}-pagination-dropdown`}
					>
						{pageSizeList.map((option) => (
							<MenuItem
								key={option}
								value={option}
								data-testid={`${tableName}-pagination-dropdown-option`}
							>
								{option}
							</MenuItem>
						))}
					</GeneralDropdown>
				</FormControl>
			</div>
			<Box className="tablePaginationContainer-pageNav">
				<FooterIconButton
					onClick={onPreviousPageClicked}
					disabled={!canPreviousPage}
					data-testid="leftArrowIcon"
				>
					<LeftArrow height={16} width={16} />
				</FooterIconButton>
				<div data-testid={`${tableName}-pagination-page-label`}>{`Page ${
					totalPages === 0 ? page : page + 1
				} of ${totalPages}`}</div>
				<FooterIconButton
					onClick={onNextPageClicked}
					disabled={!canNextPage}
					data-testid="rightArrowIcon"
				>
					<RightArrow height={16} width={16} />
				</FooterIconButton>
			</Box>
		</PaginationFooterContainer>
	);
}

TablePaginationFooter.propTypes = {
	page: PropTypes.number,
	rowsPerPage: PropTypes.number,
	count: PropTypes.number,
	pageSizeList: PropTypes.arrayOf(PropTypes.number),
	onNextPageClicked: PropTypes.func,
	setPageSize: PropTypes.func,
	onPreviousPageClicked: PropTypes.func,
	canPreviousPage: PropTypes.bool,
	canNextPage: PropTypes.bool,
	leftHandMessage: PropTypes.node,
	tableName: PropTypes.string,
};

export default TablePaginationFooter;
