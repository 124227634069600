import React from "react";
import { Box, styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { COMPONENT_ID } from "../../constants";

const CustomBox = styled(Box)(({ theme }) => ({
	color: theme.palette.background.default,
	textAlign: "center",
}));

export default function LoadingIndicator({ size }) {
	return (
		<CustomBox>
			<CircularProgress
				color="inherit"
				size={size}
				data-testid={COMPONENT_ID.LOADER_TEST_ID}
			/>
		</CustomBox>
	);
}

LoadingIndicator.propTypes = {
	size: PropTypes.number,
};
