import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import enLocale from "date-fns/locale/en-US";

import "./App.css";
import { ToastProvider } from "../context/toastContext";
import DataReports from "./DataReports";
import { DataReportsProvider } from "../context/dataReportsContext";

function App() {
	const queryClient = new QueryClient();
	return (
		<QueryClientProvider client={queryClient}>
			<LocalizationProvider
				dateAdapter={AdapterDateFns}
				adapterLocale={enLocale}
			>
				<ToastProvider>
					<DataReportsProvider>
						<DataReports />
					</DataReportsProvider>
				</ToastProvider>
			</LocalizationProvider>
		</QueryClientProvider>
	);
}

export default App;
