import React, { createContext, useContext, useReducer, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { DATA_REPORTS_CONTEXT } from "../constants";
import MESSAGE_STRINGS from "../constants/en-us";
import { isValidDate } from "../utils/helpers";

export const DataReportsContext = createContext();

export const useDataReportsContext = () => useContext(DataReportsContext);

const initialState = {
	isFirstTime: true,
	startDate: {
		value: null,
		errorMessage: MESSAGE_STRINGS["Validation.requiredField"],
		isTouched: false,
	},
	startTime: {
		value: null,
		errorMessage: MESSAGE_STRINGS["Validation.requiredField"],
		isTouched: false,
	},
	endTime: {
		value: null,
		errorMessage: MESSAGE_STRINGS["Validation.requiredField"],
		isTouched: false,
	},
	functionalArea: { value: null },
	functionalAreaOptions: [
		{
			label: MESSAGE_STRINGS["DataReports.FunctionalArea.FH"],
			value: "FH",
			disabled: true,
		},
		{
			label: MESSAGE_STRINGS["DataReports.FunctionalArea.OEE"],
			value: "OEE",
			disabled: true,
		},
		{
			label: MESSAGE_STRINGS["DataReports.FunctionalArea.SOC"],
			value: "SOC",
			disabled: true,
		},
		{
			label: MESSAGE_STRINGS["DataReports.FunctionalArea.EC"],
			value: "EC",
			disabled: true,
		},
	],
	timezone: undefined,
};
function isTimeAfterCurrentTime(startDate, time, timezone) {
	const currentTime = timezone ? moment().tz(timezone) : moment.tz();
	currentTime.subtract(30, "minute");
	return time && isValidDate(time) && time.isAfter(currentTime);
}
function validateStartAndEndTime(startDate, startTime, endTime, timezone) {
	const newStartTime = { ...startTime };
	const newEndTime = { ...endTime };
	if (startDate && startTime.value && isValidDate(startTime.value)) {
		const tempStartTime = timezone
			? moment.tz(
					`${startDate?.format("MM-DD-YYYY")} ${startTime.value.format(
						"HH:mm"
					)}`,
					"MM-DD-YYYY HH:mm",
					timezone
			  )
			: moment(
					`${startDate?.format("MM-DD-YYYY")} ${startTime.value.format(
						"HH:mm"
					)}`,
					"MM-DD-YYYY HH:mm"
			  );

		if (isTimeAfterCurrentTime(startDate, tempStartTime, timezone)) {
			newStartTime.errorMessage =
				MESSAGE_STRINGS["DateReport.validation.startTime.afterTime"];
		} else if (
			newStartTime.errorMessage ===
			MESSAGE_STRINGS["DateReport.validation.startTime.afterTime"]
		)
			newStartTime.errorMessage = "";
	}

	if (!startDate || !endTime.value || !isValidDate(endTime.value))
		return { newStartTime, newEndTime };
	const tempEndTime = timezone
		? moment.tz(
				`${startDate?.format("MM-DD-YYYY")} ${endTime.value.format("HH:mm")}`,
				"MM-DD-YYYY HH:mm",
				timezone
		  )
		: moment(
				`${startDate?.format("MM-DD-YYYY")} ${endTime.value.format("HH:mm")}`,
				"MM-DD-YYYY HH:mm"
		  );
	if (endTime.value.isSameOrBefore(startTime.value)) {
		tempEndTime.add(1, "day");
	}

	if (isTimeAfterCurrentTime(startDate, tempEndTime, timezone)) {
		newEndTime.errorMessage =
			MESSAGE_STRINGS["DateReport.validation.endTime.afterTime"];
	} else if (
		newEndTime.errorMessage ===
		MESSAGE_STRINGS["DateReport.validation.endTime.afterTime"]
	)
		newEndTime.errorMessage = "";
	return { newStartTime, newEndTime };
}
function validateStartDate(newValue, timezone) {
	const currentDate = timezone ? moment().tz(timezone) : moment.tz();
	const minDate = currentDate.clone();
	minDate.subtract(365, "days");
	if (newValue === null) {
		return MESSAGE_STRINGS["Validation.requiredField"];
	}
	if (newValue && !newValue.isValid()) {
		return MESSAGE_STRINGS["DateReport.validation.dateField.invalidDate"];
	}
	if (newValue.isAfter(currentDate)) {
		return MESSAGE_STRINGS["DateReport.validation.dateField.futureDate"];
	}
	if (newValue.isBefore(minDate, "day")) {
		return MESSAGE_STRINGS["DateReport.validation.dateField.pastDate"];
	}
	return "";
}
function dataReportsReducer(state, action) {
	const { type, payload } = action;
	switch (type) {
		case DATA_REPORTS_CONTEXT.UPDATE_START_DAY: {
			const { newValue } = payload;
			const newStartDate = { ...state.startDate };
			newStartDate.errorMessage = "";
			newStartDate.value = newValue;
			newStartDate.isTouched = true;
			newStartDate.errorMessage = validateStartDate(newValue, state.timezone);
			const { newStartTime, newEndTime } = validateStartAndEndTime(
				newValue,
				state.startTime,
				state.endTime,
				state.timezone
			);

			return {
				...state,
				startDate: newStartDate,
				startTime: newStartTime,
				endTime: newEndTime,
			};
		}
		case DATA_REPORTS_CONTEXT.UPDATE_START_TIME: {
			const { newValue } = payload;
			const newStartTime = { ...state.startTime };
			newStartTime.errorMessage = "";
			newStartTime.isTouched = true;
			newStartTime.value = newValue;

			if (newValue === null) {
				newStartTime.errorMessage = MESSAGE_STRINGS["Validation.requiredField"];
			} else if (!isValidDate(newValue) || !newValue.isValid()) {
				newStartTime.errorMessage =
					MESSAGE_STRINGS["DateReport.validation.timeField.invalidTime"];
			}
			const { newStartTime: updatedStartTime, newEndTime } =
				validateStartAndEndTime(
					state.startDate.value,
					newStartTime,
					state.endTime,
					state.timezone
				);

			return { ...state, startTime: updatedStartTime, endTime: newEndTime };
		}
		case DATA_REPORTS_CONTEXT.UPDATE_END_TIME: {
			const { newValue } = payload;
			const newEndTime = { ...state.endTime };
			newEndTime.errorMessage = "";
			newEndTime.isTouched = true;
			newEndTime.value = newValue;

			if (newValue === null) {
				newEndTime.errorMessage = MESSAGE_STRINGS["Validation.requiredField"];
			} else if (!isValidDate(newValue) || !newValue.isValid()) {
				newEndTime.errorMessage =
					MESSAGE_STRINGS["DateReport.validation.timeField.invalidTime"];
			}
			const { newStartTime, newEndTime: updatedEndTime } =
				validateStartAndEndTime(
					state.startDate.value,
					state.startTime,
					newEndTime,
					state.timezone
				);

			return { ...state, endTime: updatedEndTime, startTime: newStartTime };
		}
		case DATA_REPORTS_CONTEXT.UPDATE_FUNCTIONAL_AREA: {
			const { newValue } = payload;
			return { ...state, functionalArea: { value: newValue } };
		}
		case DATA_REPORTS_CONTEXT.ON_DOWNLOAD: {
			return { ...state, isFirstTime: false };
		}
		case DATA_REPORTS_CONTEXT.LOAD_DATA: {
			const { timezone, functionalAreaOptions } = payload;
			return { ...state, functionalAreaOptions, timezone };
		}
		default:
			return state;
	}
}

export function DataReportsProvider({ children }) {
	const [dataReportsState, dataReportsDispatch] = useReducer(
		dataReportsReducer,
		initialState
	);

	const dataReportsData = useMemo(
		() => ({ dataReportsState, dataReportsDispatch }),
		[dataReportsState]
	);

	return (
		<DataReportsContext.Provider value={dataReportsData}>
			{children}
		</DataReportsContext.Provider>
	);
}

DataReportsProvider.propTypes = {
	children: PropTypes.node,
};
