import React from "react";
import PropTypes from "prop-types";
import { alpha, Box, styled } from "@mui/material";

import ErrorIcon from "../../assets/img/errorIcon.svg";
import WarningIcon from "../../assets/img/warningIcon.svg";
import SuccessIcon from "../../assets/img/successIcon.svg";
import InfoIcon from "../../assets/img/infoIcon.svg";
import { BLOCK_LEVEL_INDICATOR_TYPES, INDICATOR_SIZE } from "../../constants";

const iconTypes = {
	[BLOCK_LEVEL_INDICATOR_TYPES.ERROR]: <ErrorIcon height="24" width="24" />,
	[BLOCK_LEVEL_INDICATOR_TYPES.WARNING]: <WarningIcon height="24" width="24" />,
	[BLOCK_LEVEL_INDICATOR_TYPES.SUCCESS]: <SuccessIcon height="24" width="24" />,
	[BLOCK_LEVEL_INDICATOR_TYPES.INFO]: <InfoIcon height="24" width="24" />,
};
const colorTypes = {
	[BLOCK_LEVEL_INDICATOR_TYPES.ERROR]: "errorColor",
	[BLOCK_LEVEL_INDICATOR_TYPES.WARNING]: "tangerineColor",
	[BLOCK_LEVEL_INDICATOR_TYPES.SUCCESS]: "successColor",
	[BLOCK_LEVEL_INDICATOR_TYPES.INFO]: "infoColor",
};
const CustomizedBox = styled(Box, {
	shouldForwardProp: (prop) => prop !== "indicatorColor",
})(({ theme, indicatorColor }) => ({
	background: alpha(theme.palette.background[indicatorColor], 0.05),
	border: `0.0625em solid ${theme.palette.background[indicatorColor]}`,
	borderRadius: "0.25em",
	color: theme.palette.text.primary,
	fontFamily: "Open Sans",
	fontStyle: "normal",
	fontSize: "0.875rem",
	lineHeight: "1.25rem",
}));
function BlockLevelIndicator({
	type = BLOCK_LEVEL_INDICATOR_TYPES.ERROR,
	children,
	fallbackIcon = <ErrorIcon height="24" width="24" />,
	fallbackColor = "errorColor",
	variant = INDICATOR_SIZE.STANDARD,
	width: indicatorWidth = "inherit",
	...props
}) {
	const indicatorColor = colorTypes[type] || fallbackColor;
	const indicatorIcon = iconTypes[type] || fallbackIcon;
	return (
		<Box display="flex" flexDirection="column" {...props}>
			<CustomizedBox
				variant={variant}
				indicatorColor={indicatorColor}
				className="page-level-indicator"
				display="flex"
				alignItems="flex-start"
				width={indicatorWidth}
				paddingX={2}
				paddingY={variant === INDICATOR_SIZE.STANDARD ? "1rem" : "0.375rem"}
				data-testid={`block-level-indicator-${type}`}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "flex-start",
						height: "100%",
						marginRight: 2,
					}}
				>
					{indicatorIcon}
				</Box>
				<Box
					display="flex"
					alignItems="center"
					minHeight={24} /* Height of Icon */
				>
					<Box
						paddingLeft={0}
						display="flex"
						flexDirection="column"
						flex={1}
						data-testid="blocklevel-indicator-message"
					>
						{children}
					</Box>
				</Box>
			</CustomizedBox>
		</Box>
	);
}

BlockLevelIndicator.propTypes = {
	type: PropTypes.string,
	children: PropTypes.node,
	fallbackIcon: PropTypes.node,
	fallbackColor: PropTypes.string,
	variant: PropTypes.string,
	width: PropTypes.string,
};
BlockLevelIndicator.propTypes = {};

export default BlockLevelIndicator;
