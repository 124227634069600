import React from "react";
import PropTypes from "prop-types";
import {
	useReactTable,
	getCoreRowModel,
	getPaginationRowModel,
	flexRender,
} from "@tanstack/react-table";
import {
	Paper,
	Skeleton,
	styled,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import TablePaginationFooter from "./TablePaginationFooter";

const HeaderCell = styled(TableCell)(({ theme }) => ({
	background: theme.palette.background.darkishBlackBlue,
	padding: ".96875rem 1rem",
	color: theme.palette.text.lightUnitGrey,
	borderBottom: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
}));

const TablePaper = styled(Paper)(({ theme }) => ({
	width: "100%",
	overflow: "hidden",
	background: theme.palette.background.blackGrey,
	boxShadow: "none",
	border: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
}));
const TableBodyRow = styled(TableRow)(() => ({
	height: "3rem",
}));
const TableBodyCell = styled(TableCell)(({ theme }) => ({
	padding: "0 1rem",
	borderBottom: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
}));

function SMFTable({
	rows = [],
	columns = [],
	stickyHeader = true,
	maxHeight = "100%",
	minHeight = "100%",
	height = "100%",
	initialPagination = { pageIndex: 0, pageSize: 5 },
	isPaginationEnabled = false,
	pageSizeList,
	tableName = "smf-table",
	loading = false,
	emptyTableLabel = "",
}) {
	const tableData = React.useMemo(
		() => (loading ? Array(5).fill({}) : rows),
		[loading, rows]
	);
	const tableColumns = React.useMemo(
		() =>
			loading
				? columns.map((column) => ({
						...column,
						cell: (
							<Skeleton
								height={42}
								className="smf-table-loader"
								data-testid="smf-table-skeleton"
							/>
						),
				  }))
				: columns,
		[loading, columns]
	);

	const instance = useReactTable({
		data: tableData,
		columns: tableColumns,
		initialState: {
			pagination: initialPagination,
		},
		getCoreRowModel: getCoreRowModel(),
		...(isPaginationEnabled
			? {
					autoResetPageIndex: false,
					getPaginationRowModel: getPaginationRowModel(),
			  }
			: {}),
	});
	const isEmptyLabelDisplayed =
		instance.getRowModel().rows.length === 0 && Boolean(emptyTableLabel);

	return (
		<TablePaper className="smf-table-paper" data-testid={`${tableName}-paper`}>
			<TableContainer
				sx={{ maxHeight, height, minHeight }}
				className="smf-table-container"
				data-testid={`${tableName}-container`}
			>
				<Table
					stickyHeader={stickyHeader}
					aria-label="table"
					className="smf-table"
					sx={{
						height: isEmptyLabelDisplayed ? "100%" : "auto",
					}}
				>
					{instance.getHeaderGroups().map((headerGroup) => (
						<TableHead
							key={headerGroup.id}
							className="smf-table-header"
							data-testid={`${tableName}-head`}
						>
							<TableRow
								className="smf-table-header-row"
								data-testid={`${tableName}-header-row`}
							>
								{headerGroup.headers.map((header) => (
									<HeaderCell
										key={header.id}
										colSpan={header.colSpan}
										width={header.column.columnDef.width}
										sx={{ ...header.column.columnDef.sx }}
										className="smf-table-header-cell"
										data-testid={`${tableName}-header-cell`}
									>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext()
											  )}
									</HeaderCell>
								))}
							</TableRow>
						</TableHead>
					))}
					<TableBody
						className="smf-table-body"
						data-testid={`${tableName}-body`}
					>
						{isEmptyLabelDisplayed ? (
							<TableBodyRow
								sx={{ "&.smf-table": { height: "100%" } }}
								data-testid={`${tableName}-empty-row`}
							>
								<TableBodyCell
									colSpan={instance.getHeaderGroups()[0].headers.length}
									align="center"
								>
									No Data Available : (
								</TableBodyCell>
							</TableBodyRow>
						) : (
							instance.getRowModel().rows.map((row) => {
								return (
									<TableBodyRow
										tabIndex={-1}
										key={row.id}
										sx={{
											...(!isPaginationEnabled
												? { "&:last-child td ": { border: 0 } }
												: {}),
										}}
										className="smf-table-body-row"
										data-testid={`${tableName}-body-row`}
									>
										{row.getVisibleCells().map((cell) => {
											return (
												<TableBodyCell
													key={cell.id}
													align={cell.align}
													className="smf-table-body-cell"
													data-testid={`${tableName}-${cell.column.id}-body-cell`}
												>
													{flexRender(
														cell.column.columnDef.cell,
														cell.getContext()
													)}
												</TableBodyCell>
											);
										})}
									</TableBodyRow>
								);
							})
						)}
					</TableBody>
				</Table>
			</TableContainer>
			{isPaginationEnabled && (
				<TablePaginationFooter
					pageSizeList={pageSizeList}
					setPageSize={instance.setPageSize}
					canPreviousPage={instance.getCanPreviousPage()}
					canNextPage={instance.getCanNextPage()}
					onPreviousPageClicked={instance.previousPage}
					onNextPageClicked={instance.nextPage}
					count={rows?.length}
					rowsPerPage={instance.getState().pagination.pageSize}
					page={instance.getState().pagination.pageIndex}
					tableName={tableName}
				/>
			)}
		</TablePaper>
	);
}

SMFTable.propTypes = {
	rows: PropTypes.arrayOf(PropTypes.shape({})),
	columns: PropTypes.arrayOf(PropTypes.shape({})),
	height: PropTypes.string,
	minHeight: PropTypes.string,
	maxHeight: PropTypes.string,
	initialPagination: PropTypes.shape({
		pageSize: PropTypes.number,
		pageIndex: PropTypes.string,
	}),
	isPaginationEnabled: PropTypes.bool,
	pageSizeList: PropTypes.arrayOf(PropTypes.number),
	stickyHeader: PropTypes.bool,
	tableName: PropTypes.string,
	loading: PropTypes.bool,
	emptyTableLabel: PropTypes.string,
};

export default SMFTable;
