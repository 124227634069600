import * as React from "react";
import moment from "moment-timezone";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import PropTypes from "prop-types";
import GeneralTextField from "../GeneralTextField";
import CalendarIcon from "../../assets/img/calendarIcon.svg";

const paperSx = {
	backgroundColor: (theme) => theme.palette.background.configLighterDark,
	color: "white",
	"& .MuiTypography-root": {
		color: "white",
	},
	"& .MuiPickersDay-root": {
		backgroundColor: (theme) => theme.palette.background.configLighterDark,
		"&.Mui-disabled": {
			color: "#53565A",
		},
	},
	"& .MuiPickersDay-today": {
		border: (theme) =>
			`0.0625em solid ${theme.palette.border.primary} !important`,
	},
	"& .MuiPickersDay-dayOutsideMonth": {
		color: (theme) => theme.palette.background.lightWhiteGrey,
	},
	"& .Mui-selected": {
		backgroundColor: (theme) =>
			`${theme.palette.background.saveGreen} !important`,
		color: (theme) =>
			`${theme.palette.background.configLighterDark} !important`,
		"&:focus": {
			backgroundColor: (theme) =>
				`${theme.palette.background.saveGreen} !important`,
			color: (theme) =>
				`${theme.palette.background.configLighterDark} !important`,
		},
		"&:hover": {
			backgroundColor: (theme) => theme.palette.background.saveGreen,
			color: (theme) => theme.palette.text.configLighterDark,
		},
	},
	"& .MuiPickersCalendarHeader-switchViewButton": {
		color: (theme) => theme.palette.text.primary,
	},
	"& .MuiPickersArrowSwitcher-button": {
		color: (theme) => theme.palette.text.primary,
	},

	"& .Mui-disabled": {
		pointerEvents: "none",
		cursor: "default",
		color: (theme) => `${theme.palette.text.darkGrey} !important`,
	},

	"& .PrivatePickersSlideTransition-root": {
		minHeight: "15rem",
	},
};

function SMFDatePicker({
	value = null,
	onChange = () => null,
	error = false,
	dataTestId = "date-picker",
	timezone = "",
	pastDaysDisable = null,
	onFocus,
	onBlur,
	...props
}) {
	const [open, setOpen] = React.useState(false);
	if (timezone) moment.tz.setDefault(timezone);
	const minDate = timezone ? moment().tz(timezone) : moment.tz();
	if (pastDaysDisable) {
		minDate.subtract(pastDaysDisable, "days");
	}
	return (
		<LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
			<DesktopDatePicker
				open={open}
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
				value={value}
				onChange={onChange}
				components={{
					OpenPickerIcon: (args) => (
						<CalendarIcon
							height={20}
							width={20}
							{...args}
							data-testid="CalendarIcon"
						/>
					),
				}}
				renderInput={(params) => (
					<GeneralTextField
						{...params}
						error={error}
						inputProps={{
							...params.inputProps,
							placeholder: "MM/DD/YYYY",
							form: {
								autocomplete: "off",
							},
						}}
						onFocus={onFocus}
						onBlur={onBlur}
						data-testid={dataTestId}
					/>
				)}
				InputProps={{
					sx: {
						"& .MuiSvgIcon-root": {
							color: (theme) => theme.palette.border.lightWhiteGrey,
						},
					},
				}}
				PaperProps={{
					sx: paperSx,
				}}
				minDate={pastDaysDisable ? minDate : undefined}
				{...props}
			/>
		</LocalizationProvider>
	);
}
SMFDatePicker.propTypes = {
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.instanceOf(moment),
		PropTypes.instanceOf(Date),
	]),
	onChange: PropTypes.func,
	error: PropTypes.bool,
	dataTestId: PropTypes.string,
	timezone: PropTypes.string,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	pastDaysDisable: PropTypes.number,
};
export default SMFDatePicker;
