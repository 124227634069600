import { useState } from "react";
import { Typography, Box } from "@mui/material";
import PropTypes from "prop-types";

import GeneralTooltip from "../../../components/GeneralTooltip";
import MESSAGE_STRINGS from "../../../constants/en-us";
import InfoToolTip from "../../../assets/img/greyInfo.svg";
import InlineErrorMessages from "../../../components/InlineErrorMessages";
import SMFDatePicker from "../../../components/SMFDatePicker";
import SMFTimePicker from "../../../components/SMFTimePicker";
import { DATA_REPORTS_CONTEXT } from "../../../constants";
import { dateType } from "../../../constants/types";

function DateField({
	value,
	onChange,
	showError,
	errorMessage,
	type,
	isTouched,
	timezone,
	...args
}) {
	const [activeState, setActiveState] = useState(false);

	const isError =
		(showError || isTouched) && Boolean(errorMessage) && !activeState;
	return (
		<Box width="10rem" marginLeft="1rem">
			<SMFDatePicker
				value={value}
				onChange={onChange}
				onFocus={() => {
					setActiveState(true);
				}}
				onBlur={() => {
					setActiveState(false);
				}}
				error={isError}
				dataTestId={`${type}-field`}
				disableFuture
				timezone={timezone}
				pastDaysDisable={365}
				{...args}
			/>
			{isError ? (
				<InlineErrorMessages
					dataTestId={`${type}-error`}
					message={errorMessage}
					marginTop="0.5rem"
				/>
			) : null}
		</Box>
	);
}
DateField.propTypes = {
	value: dateType,
	onChange: PropTypes.func,
	showError: PropTypes.bool,
	errorMessage: PropTypes.string,
	type: PropTypes.string,
	isTouched: PropTypes.bool,
	timezone: PropTypes.string,
};

function TimeField({
	value,
	onChange,
	showError,
	errorMessage,
	type,
	isTouched,
	...args
}) {
	const [activeState, setActiveState] = useState(false);
	const isError =
		!activeState && (showError || isTouched) && Boolean(errorMessage);
	return (
		<Box width="10rem" marginLeft="1rem">
			<SMFTimePicker
				dataTestId={`${type}-field`}
				value={value}
				handleChange={(newValue, inputValue) => {
					// Workaround from https://github.com/mui/mui-x/issues/5341#issuecomment-1170031087
					if (inputValue != null && inputValue.length !== 5) {
						onChange(new Date(NaN));
						return;
					}
					onChange(newValue);
				}}
				isError={isError}
				onFocus={() => {
					setActiveState(true);
				}}
				onBlur={() => {
					setActiveState(false);
				}}
				{...args}
			/>
			{isError ? (
				<InlineErrorMessages
					message={errorMessage}
					dataTestId={`${type}-error`}
					marginTop="0.5rem"
				/>
			) : null}
		</Box>
	);
}
TimeField.propTypes = {
	value: dateType,
	onChange: PropTypes.func,
	showError: PropTypes.bool,
	errorMessage: PropTypes.string,
	type: PropTypes.string,
	isTouched: PropTypes.bool,
	timezone: PropTypes.string,
};

function TimeRange({ state, dispatch }) {
	const { startDate, startTime, endTime } = state;

	return (
		<>
			<Box className="data-reports-header-row">
				<Typography className="dr-header-text" data-testid="time-range-header">
					{MESSAGE_STRINGS["DataReports.TimeRange.header"]}
				</Typography>
				<GeneralTooltip
					data-testid="time-range-tooltip-icon"
					message={
						<Typography
							variant="subtitle1"
							data-testid="time-range-tooltip-content"
						>
							{MESSAGE_STRINGS["DataReports.TimeRange.tooltip"]}
						</Typography>
					}
				>
					<InfoToolTip height={16} width={16} />
				</GeneralTooltip>
			</Box>
			<Box className="data-reports-body-row">
				<Box display="flex">
					<Typography
						className="dr-body-text"
						data-testid="time-range-start-date-text"
					>
						{MESSAGE_STRINGS["DataReports.TimeRange.startDate"]}
					</Typography>
					<DateField
						type="time-range-start-date"
						value={startDate.value}
						onChange={(newValue) => {
							dispatch({
								type: DATA_REPORTS_CONTEXT.UPDATE_START_DAY,
								payload: { newValue },
							});
						}}
						errorMessage={startDate.errorMessage}
						showError={!state.isFirstTime}
						isTouched={startDate.isTouched}
						timezone={state.timezone}
					/>
				</Box>
				<Box display="flex" marginLeft="8.5%">
					<Typography
						className="dr-body-text"
						data-testid="time-range-start-time-text"
					>
						{MESSAGE_STRINGS["DataReports.TimeRange.startTime"]}
					</Typography>
					<TimeField
						type="time-range-start-time"
						value={startTime.value}
						onChange={(newValue) => {
							dispatch({
								type: DATA_REPORTS_CONTEXT.UPDATE_START_TIME,
								payload: { newValue },
							});
						}}
						errorMessage={startTime.errorMessage}
						showError={!state.isFirstTime}
						isTouched={startTime.isTouched}
						timezone={state.timezone}
					/>
				</Box>
				<Box display="flex" marginLeft="8.5%">
					<Typography
						className="dr-body-text"
						data-testid="time-range-end-time-text"
					>
						{MESSAGE_STRINGS["DataReports.TimeRange.EndTime"]}
					</Typography>
					<TimeField
						type="time-range-end-time"
						value={endTime.value}
						onChange={(newValue) => {
							dispatch({
								type: DATA_REPORTS_CONTEXT.UPDATE_END_TIME,
								payload: { newValue },
							});
						}}
						errorMessage={endTime.errorMessage}
						showError={!state.isFirstTime}
						isTouched={endTime.isTouched}
						timezone={state.timezone}
					/>
				</Box>
			</Box>
		</>
	);
}

TimeRange.propTypes = {
	dispatch: PropTypes.func,
	state: PropTypes.shape({
		isFirstTime: PropTypes.bool,
		startDate: PropTypes.shape({
			value: dateType,
			errorMessage: PropTypes.string,
			isTouched: PropTypes.bool,
		}),
		startTime: PropTypes.shape({
			value: dateType,
			errorMessage: PropTypes.string,
			isTouched: PropTypes.bool,
		}),
		endTime: PropTypes.shape({
			value: dateType,
			errorMessage: PropTypes.string,
			isTouched: PropTypes.bool,
		}),
		timezone: PropTypes.string,
	}),
};
export default TimeRange;
