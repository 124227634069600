/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { Box, styled, Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import SMFTable from "../../components/SMFTable";
import InfoToolTip from "../../assets/img/greyInfo.svg";
import GeneralTooltip from "../../components/GeneralTooltip";
import MESSAGE_STRINGS from "../../constants/en-us";
import StatusCell from "./StatusCell";

const columnHelper = createColumnHelper();

const ReportHistoryContainer = styled("div")(({ theme }) => ({
	"& .smf-table-header-cell": {
		[theme.breakpoints.down("1800")]: {
			width: "auto",
		},
		[theme.breakpoints.up("1800")]: {
			width: "20%",
		},
	},
	"& .smf-table-header-cell:nth-of-type(2)": {
		[theme.breakpoints.up("1800")]: {
			width: "23%",
		},
		[theme.breakpoints.down("1800")]: {
			width: "28%",
		},
	},
	"& .smf-table-body-cell:nth-of-type(3)": {
		maxWidth: 0,
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
	"& .tooltip-span": {
		height: "1rem",
		marginLeft: ".75rem",
	},
	"& .inline-message-label": {
		fontSize: ".875rem",
	},
}));

function ReportHistoryTable(props) {
	const { rows, onDownload } = props;
	const columns = React.useMemo(
		() => [
			columnHelper.accessor("createdOn", {
				cell: (info) => info.getValue(),
				header: MESSAGE_STRINGS["DataReports.HistoryTable.Column.CreatedOn"],
			}),
			columnHelper.accessor("timeRange", {
				cell: (info) => info.getValue(),
				header: MESSAGE_STRINGS["DataReports.HistoryTable.Column.TimeRange"],
			}),
			columnHelper.accessor("requester", {
				cell: (info) => info.getValue(),
				header: MESSAGE_STRINGS["DataReports.HistoryTable.Column.Requester"],
			}),
			columnHelper.accessor("functionalArea", {
				cell: (info) => info.getValue(),
				header:
					MESSAGE_STRINGS["DataReports.HistoryTable.Column.FunctionalArea"],
			}),
			columnHelper.accessor("status", {
				cell: (args) => (
					<StatusCell {...args} onClick={() => onDownload(args)} />
				),
				header: MESSAGE_STRINGS["DataReports.HistoryTable.Column.Status"],
			}),
		],
		[onDownload]
	);
	return (
		<ReportHistoryContainer>
			<Box display="flex" alignItems="center">
				<Typography
					data-testid="reports-header"
					sx={{
						margin: "1rem 0 1rem 1rem",
						fontFamily: "'Open Sans'",
						fontStyle: "normal",
						fontWeight: "700",
						fontSize: ".875rem",
						lineHeight: "1.188rem",
					}}
				>
					{MESSAGE_STRINGS["DataReports.HistoryTable.title"]}
				</Typography>
				<GeneralTooltip
					data-testid="reports-tooltip-icon"
					maxWidth="25rem"
					message={
						<Typography
							variant="subtitle1"
							data-testid="reports-tooltip-content"
						>
							{MESSAGE_STRINGS["DataReports.HistoryTable.tooltip"]}
						</Typography>
					}
				>
					<InfoToolTip height={16} width={16} />
				</GeneralTooltip>
			</Box>
			<SMFTable
				columns={columns}
				rows={rows}
				height="18.5rem"
				isPaginationEnabled
				tableName="dr-table"
			/>
		</ReportHistoryContainer>
	);
}

ReportHistoryTable.propTypes = {};

export default ReportHistoryTable;
