import PropTypes from "prop-types";
import { forwardRef } from "react";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import moment from "moment-timezone";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Typography } from "@mui/material";
import GeneralTextField from "../GeneralTextField";
import ClockIcon from "../../assets/img/clockIcon.svg";
import MESSAGE_STRINGS from "../../constants/en-us";

const paperSx = {
	backgroundColor: (theme) => theme.palette.background.configLighterDark,
	color: (theme) => theme.palette.text.primary,
	"& .MuiClockNumber-root": {
		color: (theme) => theme.palette.text.primary,
	},
	"& .MuiClock-clock": {
		background: "rgba(33, 33, 33, 0.3)",
	},
	"& .MuiClock-pin ": {
		backgroundColor: (theme) => theme.palette.text.saveGreen,
	},
	"& .MuiClockPointer-root": {
		backgroundColor: (theme) => theme.palette.text.saveGreen,
	},
	"& .MuiClockPointer-thumb": {
		backgroundColor: (theme) => theme.palette.text.saveGreen,
		borderColor: (theme) => theme.palette.text.saveGreen,
	},
	"& .MuiPickersArrowSwitcher-button": {
		color: (theme) => theme.palette.text.primary,
	},
	"& .Mui-disabled": {
		pointerEvents: "none",
		cursor: "default",
		color: (theme) => `${theme.palette.text.darkGrey} !important`,
	},
	"& .MuiPickersArrowSwitcher-root": {
		// display: "none",
	},
};
const SMFTimePicker = forwardRef((props, ref) => {
	const {
		value = null,
		handleChange = () => null,
		textFieldPlaceHolder = "HH:MM",
		isError = false,
		isDisabled = false,
		id,
		dataTestId,
		timezone,
		onFocus,
		onBlur,
		...others
	} = props;
	if (timezone) moment.tz.setDefault(timezone);
	return (
		<LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
			<DesktopTimePicker
				value={value}
				disabled={isDisabled}
				onChange={handleChange}
				error={isError}
				key={id}
				components={{
					OpenPickerIcon: (args) => (
						<ClockIcon
							height={18}
							width={18}
							{...args}
							data-testid="ClockIcon"
						/>
					),
				}}
				renderInput={(params) => (
					<GeneralTextField
						{...params}
						error={isError}
						inputProps={{
							autoComplete: "off",
							...params.inputProps,
							placeholder: textFieldPlaceHolder,
						}}
						onFocus={onFocus}
						onBlur={onBlur}
						data-testid={dataTestId}
					/>
				)}
				PaperProps={{
					sx: paperSx,
				}}
				label=""
				ampm={false}
				inputFormat="HH:mm"
				ref={ref}
				showToolbar
				ToolbarComponent={() => (
					<Typography
						sx={{
							margin: "1rem",
							color: (theme) => theme.palette.text.lightUnitGrey,
							fontSize: "1rem",
							fontWeight: 700,
							paddingTop: ".3rem",
						}}
					>
						{MESSAGE_STRINGS["TimePicker.select.time"]}
					</Typography>
				)}
				{...others}
			/>
		</LocalizationProvider>
	);
});

SMFTimePicker.propTypes = {
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date),
		PropTypes.instanceOf(moment),
		PropTypes.number,
	]),
	handleChange: PropTypes.func.isRequired,
	textFieldPlaceHolder: PropTypes.string,
	isError: PropTypes.bool,
	isDisabled: PropTypes.bool,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	dataTestId: PropTypes.string,
	timezone: PropTypes.string,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
};

export default SMFTimePicker;

// Usage:
// <SMFTimePicker value={null | Date} onChange={} sx={{ width: 160 }} />
// Error rendering is controlled in the parent
