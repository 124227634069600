import { dataReports } from "@smf/ui-util-app";

export async function fetchDatareportsMetaData(params) {
	return dataReports.fetchDataReportMetaData(params);
}
export async function fetchHistoryTableData(params) {
	return dataReports.fetchReportTableData(params);
}
export async function generateDataReport(data) {
	return dataReports.generateDataReport(data);
}
export async function downloadDataReport(params) {
	return dataReports.downloadDataReport(params);
}
export default {};
