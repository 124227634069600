import { Typography, Box } from "@mui/material";
import PropTypes from "prop-types";
import BlockLevelIndicator from "../../../components/BlockLevelIndicator";
import SMFRadioGroup from "../../../components/SMFRadioGroup";
import { DATA_REPORTS_CONTEXT } from "../../../constants";
import MESSAGE_STRINGS from "../../../constants/en-us";

function FunctionalArea({ functionalArea, options, dispatch, isFirstTime }) {
	return (
		<>
			<Box className="data-reports-header-row flex-column">
				<Typography
					className="dr-header-text"
					data-testid="functional-area-header"
				>
					{MESSAGE_STRINGS["DataReports.FunctionalArea.header"]}
				</Typography>
				{!isFirstTime && functionalArea.value === null && (
					<BlockLevelIndicator
						variant="small"
						width="fit-content"
						sx={{ marginTop: "1rem" }}
						data-testid="functional-area-error"
					>
						{MESSAGE_STRINGS["DataReports.FunctionalArea.error"]}
					</BlockLevelIndicator>
				)}
			</Box>
			<Box className="data-reports-body-row">
				<SMFRadioGroup
					data-testid="functional-area-radio-group"
					sx={{
						"& .MuiFormControlLabel-root": {
							marginBottom: ".25rem",
						},
					}}
					value={functionalArea.value}
					onChange={(event) => {
						dispatch({
							type: DATA_REPORTS_CONTEXT.UPDATE_FUNCTIONAL_AREA,
							payload: { newValue: event.target.value },
						});
					}}
				>
					{options.map(({ value, label, disabled }, index) => (
						<SMFRadioGroup.FormControlLabel
							key={`radio-${value}`}
							data-testid={`radio-option-${index + 1}`}
							label={label}
							value={value}
							disabled={disabled}
						/>
					))}
				</SMFRadioGroup>
			</Box>
		</>
	);
}
FunctionalArea.propTypes = {
	functionalArea: PropTypes.shape({
		value: PropTypes.string,
	}),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
			disabled: PropTypes.bool,
		})
	),
	isFirstTime: PropTypes.bool,
	dispatch: PropTypes.func,
};
export default FunctionalArea;
