export const BUTTON_TYPE = {
	PRIMARY: "primary",
	SECONDARY: "secondary",
};
export const BUTTON_SIZE = {
	STANDARD: "standard",
	SMALL: "small",
};

export const TOAST_TYPE = {
	SUCCESS: "SUCCESS",
	ERROR: "ERROR",
	WARNING: "WARNING",
	INFO: "INFO",
};

export const BLOCK_LEVEL_INDICATOR_TYPES = {
	ERROR: "error",
	WARNING: "warning",
	SUCCESS: "success",
	INFO: "info",
};

export const COMPONENT_ID = {
	LOADER_TEST_ID: "loading-indicator",
};

export const INDICATOR_SIZE = { STANDARD: "standard", SMALL: "small" };

export const TOAST_REDUCER_CONSTANTS = {
	SHOW_SUCCESS_TOAST: "SHOW_SUCCESS_TOAST",
	SHOW_ERROR_TOAST: "SHOW_ERROR_TOAST",
	SHOW_WARNING_TOAST: "SHOW_WARNING_TOAST",
	SHOW_INFO_TOAST: "SHOW_INFO_TOAST",
	HIDE_TOAST: "HIDE_TOAST",
};

export const DATA_REPORTS_CONTEXT = {
	UPDATE_START_DAY: "UPDATE_START_DAY",
	UPDATE_START_TIME: "UPDATE_START_TIME",
	UPDATE_END_TIME: "UPDATE_END_TIME",
	UPDATE_FUNCTIONAL_AREA: "UPDATE_FUNCTIONAL_AREA",
	ON_DOWNLOAD: "ON_DOWNLOAD",
	LOAD_DATA: "LOAD_DATA",
};

export const QUERY_KEYS = {
	FETCH_META_DATA: "fetchMetaData",
	FETCH_HISTORY_DATA: "fetchHistoryTableData",
	DOWNLOAD_REPORT: "downloadDataReport",
};

export const REPORT_STATUS = {
	SUCCEEDED: "SUCCEEDED",
	FAILED: "FAILED",
	INSUFFICIENT_DATA: "INSUFFICIENT_DATA",
	IN_PROGRESS: "IN_PROGRESS",
};

export const DROPDOWN_CONSTANTS = { DEFAULT_VALUE: "SELECT" };

export const FILE_ACCEPT_TYPES = {
	CSV: ".csv, text/csv",
	IMAGE_PNG_JPEG: ".jpeg,.png,.jpg",
	XLSX: ".xlsx",
};

export const FUNCTIONAL_AREA_MAP = {
	FH: "Factory Health",
	OEE: "Overall Equipment Effectiveness",
	SOC: "Standard Operating Conditions",
	EC: "Equipment Constraints",
};
