import { Box, Divider, styled, Typography } from "@mui/material";
import { globalstate$ } from "@smf/ui-util-app";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import CommonBackdrop from "../../components/CommonBackdrop";
import LoadingIndicator from "../../components/LoadingIndicator";
import {
	DATA_REPORTS_CONTEXT,
	FILE_ACCEPT_TYPES,
	QUERY_KEYS,
} from "../../constants";
import MESSAGE_STRINGS from "../../constants/en-us";
import { useDataReportsContext } from "../../context/dataReportsContext";
import { useToastContext } from "../../context/toastContext";
import {
	downloadDataReport,
	fetchDatareportsMetaData,
	fetchHistoryTableData,
	generateDataReport,
} from "../../utils/apiHelpers";
import {
	getReportDownloadMessage,
	getReportStatusMessage,
	getSuccessToastPayload,
	getWarningToastPayload,
	showErrorType,
	simulateUrlClick,
} from "../../utils/helpers";
import ReportHistoryTable from "../ReportHistoryTable";
import ReportTable from "../ReportTable";

const isAnyErrorExists = (state) => {
	const { startDate, startTime, endTime, functionalArea } = state;
	return (
		Boolean(startDate.errorMessage) ||
		Boolean(startTime.errorMessage) ||
		Boolean(endTime.errorMessage) ||
		!functionalArea.value
	);
};

const createRequestData = (state) => {
	const reqData = {};
	const { startDate, startTime, endTime, functionalArea } = state;
	reqData.startDate = startDate?.value?.format("MM/DD/YYYY");
	reqData.startTime = startTime?.value?.format("HH:mm");
	reqData.endTime = endTime?.value?.format("HH:mm");
	reqData.functionalArea = functionalArea?.value;
	return reqData;
};

const DataReportContainer = styled(Box)(({ theme }) => ({
	color: "white",
	display: "flex",
	height: "100%",
	background: theme.palette.background.configLighterDark,
	flexDirection: "column",
	borderRadius: ".25rem",
}));

function DataReports() {
	const { dataReportsState, dataReportsDispatch } = useDataReportsContext();
	const [reportRows, setReportRows] = useState([]);
	const factoryID = globalstate$?.value?.plantId;
	const { toastDispatch } = useToastContext();
	const queryClient = useQueryClient();
	const {
		isInitialLoading: isMetaDataLoading,
		isFetching: isMetaDataFetching,
	} = useQuery({
		queryKey: [QUERY_KEYS.FETCH_META_DATA],
		queryFn: () => fetchDatareportsMetaData({ factoryID }),
		onSuccess: (response) => {
			const { functionalAreaOptions, timezone } = response;
			if (functionalAreaOptions) {
				dataReportsDispatch({
					type: DATA_REPORTS_CONTEXT.LOAD_DATA,
					payload: { functionalAreaOptions, timezone },
				});
			}
		},
		refetchOnWindowFocus: false,
		retry: false,
	});
	const { isInitialLoading: isFethcHistoryLoading } = useQuery({
		queryKey: [QUERY_KEYS.FETCH_HISTORY_DATA],
		queryFn: () => fetchHistoryTableData({ factoryID }),
		onSuccess: (response) => {
			setReportRows(response);
		},
		refetchOnWindowFocus: false,
		retry: false,
		refetchInterval: 30000,
		refetchIntervalInBackground: true,
	});
	const { mutate: generateReportAPI, isLoading: isGenerateReportLoading } =
		useMutation((data) => generateDataReport(data), {
			onSuccess: (response) => {
				if (response?.isNewRecord || response?.isRetry) {
					toastDispatch(
						getSuccessToastPayload(
							MESSAGE_STRINGS["DataReports.HistoryTable.Success.Toast"],
							"up"
						)
					);
					const arr = JSON.parse(JSON.stringify(reportRows));
					const indexOfRows = arr?.findIndex(
						(item) => item.dataReportID === response?.data?.dataReportID
					);
					if (indexOfRows > -1) {
						arr.splice(indexOfRows, 1);
					}
					arr.splice(0, 0, response.data);
					setReportRows([...arr]);
					queryClient.invalidateQueries([QUERY_KEYS.FETCH_HISTORY_DATA]);
					return response;
				}

				const tempArray = JSON.parse(JSON.stringify(reportRows));
				const index = tempArray?.findIndex(
					(item) => item.dataReportID === response.dataReportID
				);
				if (tempArray[index]?.status === "IN_PROGRESS") {
					tempArray[index].status = response.status;
					setReportRows([...tempArray]);
				}

				toastDispatch(
					getWarningToastPayload(getReportStatusMessage(response.status), "up")
				);
				return response;
			},
			onError: (error) => {
				toastDispatch(showErrorType(error?.response?.data?.message, "up"));
			},
		});

	const { mutate: onDataReportDownload, isLoading: isDownalodReportLoading } =
		useMutation(
			(data) => downloadDataReport({ dataReportID: data.dataReportID }),
			{
				onSuccess: (response, variables) => {
					if (response?.url) {
						simulateUrlClick(response.url, FILE_ACCEPT_TYPES.XLSX);
						toastDispatch(
							getSuccessToastPayload(
								getReportDownloadMessage(variables.functionalArea),
								"up"
							)
						);
					}
				},
			}
		);
	function onGenerate() {
		dataReportsDispatch({ type: DATA_REPORTS_CONTEXT.ON_DOWNLOAD });
		if (!isAnyErrorExists(dataReportsState)) {
			generateReportAPI({ factoryID, ...createRequestData(dataReportsState) });
		}
	}

	function onDownload(args) {
		const {
			row: { original },
		} = args;
		onDataReportDownload({
			dataReportID: original.dataReportID,
			functionalArea: original.functionalArea,
		});
	}

	return (
		<DataReportContainer>
			<Box sx={{ overflowY: "scroll", height: "100%", padding: "1rem" }}>
				<Box marginBottom="2rem">
					<Typography
						data-testid="data-reports-header"
						sx={{
							fontWeight: 400,
							fontSize: "1.5rem",
							lineHeight: "2rem",
						}}
					>
						{MESSAGE_STRINGS["DataReports.main.header"]}
					</Typography>
					<Divider
						sx={{
							border: (theme) =>
								`.0625rem solid ${theme.customColors.dividerGrey}`,
							marginTop: "0.5rem",
							marginBottom: "1rem",
						}}
					/>
					<Typography
						sx={{
							fontWeight: 700,
							fontSize: ".875rem",
							lineHeight: "1.188rem",
						}}
						data-testid="data-reports-caption"
					>
						{MESSAGE_STRINGS["DataReports.main.caption"]}
					</Typography>
				</Box>
				<ReportTable
					state={dataReportsState}
					dispatch={dataReportsDispatch}
					onGenerate={onGenerate}
				/>
				<ReportHistoryTable
					rows={reportRows}
					isLoading={isFethcHistoryLoading}
					onDownload={onDownload}
				/>
				<CommonBackdrop
					open={
						isMetaDataLoading ||
						isMetaDataFetching ||
						isGenerateReportLoading ||
						isDownalodReportLoading ||
						isFethcHistoryLoading
					}
				>
					<LoadingIndicator />
				</CommonBackdrop>
			</Box>
		</DataReportContainer>
	);
}

export default DataReports;
