import moment from "moment-timezone";
import PropTypes from "prop-types";

// Use this file to define reusable proptypes

export const dateType = PropTypes.oneOfType([
	PropTypes.instanceOf(Date),
	PropTypes.instanceOf(moment),
]);

export default {};
