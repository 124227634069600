import { FormControlLabel, Radio, RadioGroup, styled } from "@mui/material";
import PropTypes from "prop-types";

function SMFRadioGroup({ value, handleChange, children, ...args }) {
	return (
		<RadioGroup value={value} onChange={handleChange} {...args}>
			{children}
		</RadioGroup>
	);
}
SMFRadioGroup.propTypes = {
	value: PropTypes.string,
	handleChange: PropTypes.func,
};

const CustomisedRadio = styled(Radio)(({ theme }) => ({
	color: theme.palette.text.primary,
	"&.Mui-checked": {
		color: theme.palette.text.primary,
		"& .MuiSvgIcon-root:last-child": {
			path: {
				fill: theme.palette.text.saveGreen,
			},
		},
	},
	"&.Mui-disabled": {
		color: "#666666",
		"& .MuiSvgIcon-root:last-child": {
			path: {
				fill: "#666666",
			},
		},
	},
}));

function SMFFormControlLabel({ value, label, ...args }) {
	return (
		<FormControlLabel
			sx={{
				color: (theme) => theme.palette.text.primary,
				"& .MuiFormControlLabel-label": {
					lineHeight: "1.25rem",
					fontSize: ".875rem",
					"&.Mui-disabled": {
						color: "#666666",
					},
				},
			}}
			value={value}
			control={<CustomisedRadio />}
			label={label}
			{...args}
		/>
	);
}
SMFFormControlLabel.propTypes = {
	value: PropTypes.string,
	label: PropTypes.string,
};

SMFRadioGroup.FormControlLabel = SMFFormControlLabel;

SMFRadioGroup.propTypes = {
	children: PropTypes.node,
};

export default SMFRadioGroup;
