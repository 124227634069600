import React from "react";
import SMFButton from "../../components/SMFButton";
import InlineErrorMessages from "../../components/InlineErrorMessages";
import MESSAGE_STRINGS from "../../constants/en-us";
import { REPORT_STATUS } from "../../constants";

function StatusCell(info) {
	const { getValue, onClick } = info;
	const value = getValue();
	if (value === REPORT_STATUS.SUCCEEDED) {
		return (
			<SMFButton
				type="secondary"
				buttonSize="small"
				sx={{ margin: 0, marginLeft: "-.5rem", fontWeight: 600 }}
				onClick={onClick}
				data-testid="dr-table-download"
			>
				{MESSAGE_STRINGS["DataReports.Status.Succeeded"]}
			</SMFButton>
		);
	}
	if (value === REPORT_STATUS.IN_PROGRESS) return "In-Progress";
	if (value === REPORT_STATUS.FAILED) {
		return (
			<InlineErrorMessages
				message={MESSAGE_STRINGS["DataReports.Status.Failed"]}
			/>
		);
	}
	if (value === REPORT_STATUS.INSUFFICIENT_DATA) {
		return MESSAGE_STRINGS["DataReports.Status.InsufficientData"];
	}
}

export default StatusCell;
