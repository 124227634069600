import { Box, Paper, styled } from "@mui/material";
import PropTypes from "prop-types";
import SMFButton from "../../components/SMFButton";
import MESSAGE_STRINGS from "../../constants/en-us";
import { dateType } from "../../constants/types";

import FunctionalArea from "./FunctionalArea/FunctionalArea";
import TimeRange from "./TimeRange/TimeRange";

const TablePaper = styled(Paper)(({ theme }) => ({
	width: "100%",
	overflow: "hidden",
	background: theme.palette.background.blackGrey,
	boxShadow: "none",
	border: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
}));
const TableContainer = styled(Box)(({ theme }) => ({
	display: "flex",
	width: "100%",
	color: theme.palette.text.primary,
	flexDirection: "column",
	"& .tooltip-span": {
		height: "1rem",
		marginLeft: ".75rem",
	},
	"& .dr-header-text": {
		fontWeight: 700,
		fontSize: "0.875rem",
		lineHeight: "1rem",
	},
	"& .dr-body-text": {
		lineHeight: "2rem",
		fontSize: ".875rem",
	},
	"& .data-reports-header-row": {
		display: "flex",
		background: theme.palette.background.darkishBlackBlue,
		borderBottom: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
		padding: "1.25rem 1rem",
		"&.flex-row": {
			alignItems: "center",
		},
		"&.flex-column": {
			alignItems: "flext-start",
			flexDirection: "column",
		},
	},
	"& .data-reports-body-row": {
		borderBottom: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
		padding: ".5rem 1rem",
		display: "flex",
	},
	"& .inline-message-icon": {
		marginTop: ".0625rem",
		marginBottom: ".0625rem",
		alignItems: "flex-start",
	},
	"& .inline-message-container": {
		width: "18rem",
		alignItems: "flex-start",
	},
}));

const TableFooter = styled(Box)(({ theme }) => ({
	minHeight: "3.5rem",
	background: theme.palette.background.darkishBlackBlue,
	padding: "0 1rem",
	alignItems: "center",
	display: "flex",
	justifyContent: "flex-end",
}));

function ReportTable({ state, dispatch, onGenerate }) {
	const {
		startDate,
		startTime,
		endTime,
		functionalArea,
		functionalAreaOptions,
	} = state;
	const isFormTouched =
		(startDate.isTouched && Boolean(startDate.errorMessage)) ||
		(startTime.isTouched && Boolean(startTime.errorMessage)) ||
		(endTime.isTouched && Boolean(endTime.errorMessage));

	const isError =
		Boolean(startDate.errorMessage) ||
		Boolean(startTime.errorMessage) ||
		Boolean(endTime.errorMessage) ||
		!functionalArea.value;

	const isDownloadDisabled = (!state.isFirstTime || isFormTouched) && isError;

	return (
		<TablePaper>
			<TableContainer>
				<TimeRange state={state} dispatch={dispatch} />
				<FunctionalArea
					functionalArea={functionalArea}
					options={functionalAreaOptions}
					dispatch={dispatch}
					isFirstTime={state.isFirstTime}
				/>
				<TableFooter>
					<SMFButton
						sx={{
							width: "10rem",
							fontWeight: 600,
							lineHeight: "1rem",
							fontSize: ".875rem",
						}}
						onClick={onGenerate}
						disabled={isDownloadDisabled}
						data-testid="data-reports-download"
					>
						{MESSAGE_STRINGS["DataReports.button.download"]}
					</SMFButton>
				</TableFooter>
			</TableContainer>
		</TablePaper>
	);
}
ReportTable.propTypes = {
	state: PropTypes.shape({
		isFirstTime: PropTypes.bool,
		startDate: PropTypes.shape({
			value: dateType,
			errorMessage: PropTypes.string,
			isTouched: PropTypes.bool,
		}),
		startTime: PropTypes.shape({
			value: dateType,
			errorMessage: PropTypes.string,
			isTouched: PropTypes.bool,
		}),
		endTime: PropTypes.shape({
			value: dateType,
			errorMessage: PropTypes.string,
			isTouched: PropTypes.bool,
		}),
		functionalArea: PropTypes.shape({
			value: PropTypes.string,
		}),
		functionalAreaOptions: PropTypes.arrayOf(
			PropTypes.shape({
				label: PropTypes.string,
				value: PropTypes.string,
				disabled: PropTypes.bool,
			})
		),
	}),
	dispatch: PropTypes.func,
	onGenerate: PropTypes.func,
};
export default ReportTable;
