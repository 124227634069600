import { StrictMode } from "react";
import { ThemeProvider } from "@mui/material";
import { theme } from "@smf/ui-util-app";
import App from "./containers/App";

export default function Root() {
	return (
		<StrictMode>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
		</StrictMode>
	);
}
