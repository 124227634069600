import { isNaN } from "lodash";
import {
	FUNCTIONAL_AREA_MAP,
	REPORT_STATUS,
	TOAST_REDUCER_CONSTANTS,
} from "../constants";
import MESSAGE_STRINGS from "../constants/en-us";

export const simulateUrlClick = (url, fileAcceptType) => {
	const link = document.createElement("a");
	link.setAttribute("href", url);
	link.setAttribute("type", fileAcceptType);
	link.setAttribute("download", true);
	link.setAttribute("referrerpolicy", "same-origin");
	link.click();
};

export function parseToDecimal(number = "", place = 2) {
	if (number === null || number === "") return "";
	return number.toFixed(place);
}

export function isValidDate(d) {
	return !(d instanceof Date && !isNaN(d));
}

export const showErrorType = (msg, direction) => ({
	type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
	payload: {
		direction,
		message: msg || MESSAGE_STRINGS["SMFToast.message.ERROR"],
	},
});

export const getWarningToastPayload = (message, direction) => ({
	type: TOAST_REDUCER_CONSTANTS.SHOW_WARNING_TOAST,
	payload: {
		direction,
		message,
	},
});

export const getSuccessToastPayload = (message, direction) => ({
	type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
	payload: {
		direction,
		message,
	},
});

/**
 * A Routine which returns message for status while generating report
 * @param {String} status [SUCCEEDED,IN_PROGRESS,INSUFFICIENT_DATA]
 * @returns String
 */
export const getReportStatusMessage = (status) => {
	switch (status) {
		case REPORT_STATUS.SUCCEEDED:
			return MESSAGE_STRINGS["DataReports.HistoryTable.Identical.Toast"];
		case REPORT_STATUS.IN_PROGRESS:
			return MESSAGE_STRINGS[
				"DataReports.HistoryTable.IdenticalInProgress.Toast"
			];
		case REPORT_STATUS.INSUFFICIENT_DATA:
			return MESSAGE_STRINGS["DataReports.HistoryTable.Insufficient.Toast"];

		default:
			return undefined;
	}
};

/**
 * Function which returns Message for Downloading Report
 * @param {String} functionalArea
 * @returns Message String
 */
export const getReportDownloadMessage = (functionalArea) => {
	switch (functionalArea) {
		case FUNCTIONAL_AREA_MAP.FH:
			return MESSAGE_STRINGS["DataReports.HistoryTable.Download.FH"];
		case FUNCTIONAL_AREA_MAP.OEE:
			return MESSAGE_STRINGS["DataReports.HistoryTable.Download.OEE"];
		case FUNCTIONAL_AREA_MAP.SOC:
			return MESSAGE_STRINGS["DataReports.HistoryTable.Download.SOC"];
		case FUNCTIONAL_AREA_MAP.EC:
			return MESSAGE_STRINGS["DataReports.HistoryTable.Download.EC"];
		default:
			return MESSAGE_STRINGS["DataReports.HistoryTable.Download.DEFAULT"];
	}
};
